import { LOGIN, LOG_OUT } from '../action-types';
import { UPDATE_NOTIFICATION, SHOW_LOADING, UPDATE_USER } from "../mutations";
import { NOTIFICATION_MESSAGES } from "../constants";
import httpService from "../../network/http-service";
import { handleError } from "../global/actions";
import { removeCookie } from "../../utils/cookie-service";
import { API_ACTIONS } from '../api-action';

export function login(payload) {
    return async function (dispatch) {
        dispatch({ type: SHOW_LOADING, payload: true });
        try {
            const response = await httpService.post({
                action: API_ACTIONS.login,
                data: payload
            });

            if (response?.data.error) {
                dispatch({
                    type: UPDATE_NOTIFICATION,
                    payload: { variant: 'error', message: response.data.message },
                });

                return response.data;
            }

            dispatch({ type: UPDATE_USER, payload: response?.data?.user });
            dispatch({ type: UPDATE_NOTIFICATION, payload: NOTIFICATION_MESSAGES[LOGIN].success });
            return response?.data;
        } catch (err) {
            if (err.statusCode === 409) throw err;
            dispatch(handleError(LOGIN, err));
        } finally {
            dispatch({ type: SHOW_LOADING, payload: false });
        }
    };
}

export const logOut = () => {
    return (dispatch) => {
        removeCookie("ims-user");
        removeCookie("ims-token");
        dispatch({ type: UPDATE_USER, payload: null });
        dispatch({ type: UPDATE_NOTIFICATION, payload: NOTIFICATION_MESSAGES[LOG_OUT] });
    };
};

export const updateUser = (payload) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_USER, payload: payload });
    };
};