import React, { Component } from "react"
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import routes from "../../constants/routes";
import { logOut } from '../../store/auth/actions';
import { getList } from '../../store/global/actions';
import { withRouter } from "../../utils/withRouter";
import { API_ACTIONS } from "../../store/api-action";
import { logoBlack } from '../../constants/images'

class Header extends Component {
    state = {
        headerData: {
            availableFunds: 0,
            pendingPayments: 0
        }
    };

    async componentDidMount() {
        await this.getHeaderData();
    }

    async getHeaderData() {
        const { getList } = this.props;
        const response = await getList(API_ACTIONS.headerData);
        this.setState({ ...this.state, headerData: response })
    }

    render() {
        const onLogOut = () => {
            const { logOut, navigate } = this.props;
            logOut();
            navigate(routes.login);
        }

        const { user } = this.props;

        return (
            <Navbar collapseOnSelect sticky="top" data-bs-theme="dark" expand="lg" className="bg-body-tertiary">
                <Container fluid>
                    <Navbar.Brand>
                        <Link to={routes.home}>
                            <img
                                alt="Logo"
                                src={logoBlack}
                                width="50"
                                height="50"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link eventKey="2" as={Link} to={routes.expenses}>
                                Expenses
                            </Nav.Link>
                            <Nav.Link eventKey="4" as={Link} to={routes.funds}>
                                Funds
                            </Nav.Link>
                            <Nav.Link eventKey="5" as={Link} to={routes.transactions}>
                                Transactions
                            </Nav.Link>
                            <NavDropdown title="Products">
                                <NavDropdown.Item eventKey="7" as={Link} to={routes.products}>
                                    List
                                </NavDropdown.Item>
                                <NavDropdown.Item eventKey="8" as={Link} to={routes.inventory}>
                                    Inventory
                                </NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Customers">
                                <NavDropdown.Item eventKey="7" as={Link} to={routes.customers}>
                                    List
                                </NavDropdown.Item>
                                <NavDropdown.Item eventKey="8" as={Link} to={routes.customerOrder}>
                                    Orders
                                </NavDropdown.Item>
                                <NavDropdown.Item eventKey="9" as={Link} to={routes.customerSummary}>
                                    Summary
                                </NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Vendors">
                                <NavDropdown.Item eventKey="10" as={Link} to={routes.vendors}>
                                    List
                                </NavDropdown.Item>
                                <NavDropdown.Item eventKey="11" as={Link} to={routes.vendorOrders}>
                                    Orders
                                </NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Reports">
                                <NavDropdown.Item eventKey="12" as={Link} to={routes.reportPurchase}>
                                    Purchase
                                </NavDropdown.Item>
                                <NavDropdown.Item eventKey="13" as={Link} to={routes.reportSell}>
                                    Sell
                                </NavDropdown.Item>
                                <NavDropdown.Item eventKey="14" as={Link} to={routes.reportProfit}>
                                    Profit
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        <Nav className="ml-auto">
                            <Nav.Item as='div' className="d-flex pointer" onClick={() => this.getHeaderData()}>
                                <span className="badge text-bg-success my-2">
                                    <i className="uil uil-wallet fs-18"></i> Inventory
                                    <p className="mb-0">₹{this.state.headerData?.totalInvestment ?? 0}</p>
                                </span>
                                <span className="badge text-bg-info mx-2 my-2">
                                    <i className="uil uil-wallet fs-18"></i> Funds
                                    <p className="mb-0">₹{this.state.headerData?.availableFunds ?? 0}</p>
                                </span>
                                <span className="badge text-bg-warning my-2">
                                    <i className="uil uil-wallet fs-18"></i> Payments
                                    <p className="mb-0">₹{this.state.headerData?.pendingPayments ?? 0}</p>
                                </span>
                            </Nav.Item>

                            <NavDropdown align="end" title={user.firstName}>
                                <NavDropdown.Item eventKey="15" as={Link} to={routes.changePassword}>
                                    Change Password
                                </NavDropdown.Item >
                                <NavDropdown.Item eventKey="16" as={Link} onClick={onLogOut}>
                                    Logout
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}

export default connect((state) => ({ user: state.auth.currentUser }), { logOut, getList })(withRouter(Header));