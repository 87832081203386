const routes = {
    home: '/',
    login: "/login",
    changePassword: '/change-password',
    customers: '/customers',
    customerOrder: '/customers/orders',
    customerSummary: '/customers/orders/summary',
    addCustomerOrder: '/customers/orders/add',
    addCustomer: '/customers/add',
    vendors: '/vendors',
    vendorOrders: '/vendors/orders',
    addVendorOrders: '/vendors/orders/add',
    addVendor: '/vendors/add',
    products: '/products',
    addProduct: '/products/add',
    expenses: 'expenses',
    inventory: 'inventory',
    reportPurchase: 'report/purchase',
    reportSell: 'report/sell',
    reportProfit: 'report/profit',
    funds: 'funds',
    transactions: 'transactions',
    notFound: '/404'
};

export default routes;
