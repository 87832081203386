import React, { useEffect } from "react";
import { connect } from "react-redux";
import Header from "./components/shared/header";
import Notification from "./components/shared/notification";
import { AppRoutes } from './navigation/app-routes';
import { updateUser } from "./store/auth/actions";
import { getCookie } from "./utils/cookie-service";
import Loader from "./components/shared/loader";
import routes from "./constants/routes";
import { withRouter } from "./utils/withRouter";

function App(props) {
  const { navigate, location, auth } = props;

  useEffect(() => {
    if (auth.isAuthenticated === false)
      navigate(routes.login);
    else if (location.pathname.indexOf('login') > -1)
      navigate(routes.home);
  }, [auth.isAuthenticated]);

  const user = getCookie("ims-user");
  if (user && !auth.currentUser) {
    const { updateUser } = props;
    updateUser(user);
  }

  return (
    <div>
      <Notification />
      <Loader />
      <section className="bg-white">
        {auth.isAuthenticated && <Header />}
        <div className="container-fluid">
          <AppRoutes />
        </div>
      </section>
    </div>
  );
}

export default connect((state) => ({ auth: state.auth }), { updateUser })(withRouter(App));