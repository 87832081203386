export const API_ACTIONS = {
    login: 'login',
    products: 'products',
    productPriceList: 'products/price-list',
    productPrice: 'products/price',
    vendors: 'vendors',
    vendorOrder: 'vendors/orders',
    vendorOrderPayment: 'vendors/orders/payments',
    customers: 'customers',
    customerOrder: 'customers/orders',
    customerOrderSummary: 'customers/orders/summary',
    customerOrderPayment: 'customers/orders/payments',
    expenses: 'expenses',
    funds: 'funds',
    transactions: 'transactions',
    expenseType: 'master/expense-type',
    orderStatus: 'master/order-status',
    transactionType: 'master/transaction-type',
    inventory: 'inventory',
    headerData: 'master/header-data',
    reports: {
        profit: 'reports/profit',
        sell: 'reports/sell',
        purchase: 'reports/purchase'
    },
    dashboard: {
        summary: 'dashboard/summary',
        pendingPayments: 'dashboard/pending-payments',
        vendorPendingPayments: 'dashboard/vendor-pending-payments',
        customerOrder: 'dashboard/customer-orders',
        vendorOrder: 'dashboard/vendor-orders',
    }
}