import { DEFAULT_ACTION, LOGIN, LOG_OUT, CHANGE_PASSWORD } from "./action-types";

export const NOTIFICATION_MESSAGES = Object.freeze({
    [DEFAULT_ACTION]: {
        error: {
            title: "Error",
            variant: "error",
            message: "Something went wrong!",
        },
    },
    [LOGIN]: {
        success: {
            title: "Login",
            variant: "success",
            message: "Logged in successfully!",
        },
        error: {
            title: "Login",
            variant: "error",
            message: "Something went wrong!",
        }
    },
    [LOG_OUT]: {
        title: 'Logout',
        message: 'You have been logged out',
        variant: 'info'
    },
    [CHANGE_PASSWORD]: {
        success: {
            title: "Change Password",
            variant: "success",
            message: "Password changed successfully!",
        },
        error: {
            title: "Change Password",
            variant: "error",
            message: "Something went wrong!",
        },
    }
});
